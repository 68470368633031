<template>
  <v-container>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-application-user ref="search-application-user" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-local-unit ref="search-local-unit" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('masterdata_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('masterdata_reset')" /></v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="formular" color="secondary" v-bind="props">
          <span v-html="$t('masterdata_new')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :to="{ name: 'masterdata_legal_entities_create' }" id="create_btn">
          <v-list-item-title v-html="$t('masterdata_new_legal_entity_header')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn color="secondary" @click="download"><span v-html="$t('download')" /></v-btn>

    <v-data-table-server
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      density="compact"
      ><!--:items-per-page="$route.query.size ? Number.parseInt($route.query.size) : 50"
              @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      -->

      <template v-slot:[`item.edit`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' }) && item.legalEntityId">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.legalEntityId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon></router-link
          >
        </template>
      </template>

      <template v-slot:[`item.agateId`]="{ item }">
        <template v-if="item.userCount > 1">
          <router-link
            :to="{
              name: 'applicationUsers_read',
              query: {
                size: 50,
                term: 'LegalEntity_uid:' + (item.legalEntityUid || '')
              }
            }"
          >
            <span v-html="$t('masterdata_many_users')"></span>
          </router-link>
        </template>
        <template v-else>
          {{ item.agateId }}
          <span class="link" v-if="$privileges.has({ path: '/userSwitch', permission: 'read' }) && item.applicationUserId" @click="userSwitch(item)"
            ><v-icon right>mdi-chevron-right</v-icon></span
          >
        </template>
      </template>

      <template v-slot:[`item.legalEntityName1`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.legalEntityName1 }}
        </span>
      </template>

      <template v-slot:[`item.legalEntityName2`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? 'l' : 'inactive-cell'">
          {{ item.legalEntityName2 }}
        </span>
      </template>

      <template v-slot:[`item.zip`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.zip }}
        </span>
      </template>

      <template v-slot:[`item.locality`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.locality }}
        </span>
      </template>

      <template v-slot:[`item.info`]="{ item }">
        <span :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.info }}
        </span>
      </template>

      <template v-slot:[`item.legalEntityUid`]="{ item }">
        <router-link
          v-if="item.legalEntityId"
          :to="{
            name: 'masterdata_legal_entities_edit',
            params: { id: item.legalEntityId, tab: 'overview' }
          }"
          :class="item.farmerStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell tableaction link'"
        >
          {{ item.legalEntityUid && item.legalEntityUid.length > 0 ? item.legalEntityUid : $t('none') }}
        </router-link>
        <span class="link" v-if="$privileges.has({ path: '/domainSwitch', permission: 'read' })" @click="switchDomain(item)"
          ><v-icon right>mdi-chevron-right</v-icon></span
        >
      </template>

      <template v-slot:[`item.bur`]="{ item }">
        <template v-if="item.apiRoute == 'dairies' && item.localUnitId">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.farmerStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell tableaction link'"
          >
            {{ item.bur && item.bur.length > 0 ? item.bur : $t('none') }}
          </router-link>
        </template>
        <template v-if="item.apiRoute == 'farms'">
          <router-link
            v-if="item.localUnitId"
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.bur && item.bur.length > 0 ? item.bur : $t('none') }}
          </router-link>
        </template>
      </template>

      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' }) && typeof item.localUnitId == 'number' && item.localUnitId">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'tableaction link inactive-cell'"
          >
            {{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          <span :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
            {{ item.dairyIdent }}
          </span>
        </template>
      </template>

      <template v-slot:[`item.legalEntityAgisId`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.legalEntityAgisId }}
        </span>
      </template>

      <template v-slot:[`item.agisId`]="{ item }">
        <template
          v-if="
            item.apiRoute == 'dairies' && $privileges.has({ path: '/dairies', permission: 'write' }) && typeof item.localUnitId == 'number' && item.localUnitId
          "
        >
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell tableaction link'"
          >
            {{ item.agisId ? item.agisId : $t('none') }}
          </router-link>
        </template>
        <template v-if="item.apiRoute == 'dairies' && !$privileges.has({ path: '/dairies', permission: 'write' })">
          <span :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
            {{ item.agisId ? item.agisId : $t('none') }}
          </span>
        </template>
        <template
          v-if="item.apiRoute == 'farms' && $privileges.has({ path: '/farms', permission: 'write' }) && typeof item.localUnitId == 'number' && item.localUnitId"
        >
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
            :class="item.localUnitStatus === ('active' || null) ? 'tableaction link' : 'inactive-cell tableaction link'"
          >
            {{ item.agisId ? item.agisId : $t('none') }}
          </router-link>
        </template>
        <template v-if="item.apiRoute == 'farms' && !$privileges.has({ path: '/farms', permission: 'write' })">
          <span :class="item.localUnitStatus === ('active' || null) ? '' : 'inactive-cell'">
            {{ item.agisId ? item.agisId : $t('none') }}
          </span>
        </template>
      </template>

      <template v-slot:[`item.farmerStatus`]="{ item }">
        <span :class="item.farmerStatus === ('active' || null) ? '' : 'inactive-cell'">
          {{ item.farmerStatus === ('active' || null) ? $t('search_legal_entity_active') : $t('search_legal_entity_inactive') }}
        </span>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'

import { Term } from '@/services/term'
import searchApplicationUser from '@/components/searchCards/searchApplicationUser.vue'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLocalUnit from '@/components/searchCards/searchLocalUnit.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import { useSearchApplicationUserStore } from '@/store/SearchApplicationUserStore'

import { fileCreator } from '@/services/axios'
import _ from 'lodash'
import { showError } from '@/services/axios'
import { personsService } from '@/services/persons'
//http://localhost:8199/api/masterdata/search?term=applicationUser_applicationRoleId%3A1
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'masterdata_legalEntities_search',
  data() {
    return {
      dialog: false,
      items: [],
      initialized: false,
      loading: false,

      fields: <DTSHeader[]>[
        // edit
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // Agate-Nr
        {
          title: this.$t('masterdata_table_header_agateid'),
          key: 'agateId',
          sortable: true,
          align: 'left'
        },
        // Name / Firma
        {
          title: this.$t('masterdata_table_header_name_company'),
          key: 'legalEntityName1',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('masterdata_table_header_name_addition'),
          key: 'legalEntityName2',
          sortable: true
        },
        // PLZ
        {
          title: this.$t('masterdata_table_header_zip'),
          key: 'zip',
          sortable: true,
          align: 'left'
        },
        // Ort
        {
          title: this.$t('masterdata_table_header_localty'),
          key: 'locality',
          sortable: true
        },
        // Info
        {
          title: this.$t('masterdata_table_header_info'),
          key: `info${this.$getUpLang()}`,
          sortable: true
        },
        // UID
        {
          title: this.$t('masterdata_table_header_uid'),
          key: 'legalEntityUid',
          sortable: true,
          align: 'left'
        },
        // BUR
        {
          title: this.$t('masterdata_table_header_ber'),
          key: 'bur',
          sortable: true,
          align: 'left'
        },
        // MBH-ID
        {
          title: this.$t('masterdata_table_header_mbh_id'),
          key: 'dairyIdent',
          sortable: true,
          align: 'left'
        },
        // AGIS-ID Person
        {
          title: this.$t('masterdata_table_header_legalEntityAgisId'),
          key: 'legalEntityAgisId',
          sortable: false,
          align: 'left'
        },
        // AGIS-ID Betrieb
        {
          title: this.$t('masterdata_table_header_agis_id_farm'),
          key: 'agisId',
          align: 'left',
          sortable: false
        },
        {
          title: this.$t('masterdata_table_header_legal_entity_status'),
          key: 'farmerStatus',
          sortable: true
        }
      ],
      totalElements: 0
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchApplicationUserStore(), useSearchApplicationUserStore().items),
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)
      ]
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.getJavaPageOptions({ sort: ['farmerStatus,asc', 'legalEntityName1,asc'] })
      }
    }
  },
  components: {
    searchApplicationUser,
    searchLegalEntity,
    searchFarm,
    searchContact,
    searchLocalUnit,
    searchDairy
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch(() => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/masterdata/search', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-application-user'].reset()
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-farm'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-local-unit'].reset()
      this.$refs['search-dairy'].reset()
    },
    async switchDomain(legalEntity) {
      personsService.switchDomain(legalEntity)
    },
    async download() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/masterdata/search', {
          params: {
            ...this.getJavaPageOptions(),
            term: Term.stringify(this.term)
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'rechtlicheEinheiten.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loading = false
      }
    },
    async userSwitch(item) {
      await personsService.impersonateAsUser(item)
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler() {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initialized = true
      if (this.$route.query.size) this.load()
    })
  }
})
</script>
